<template>
    <table-container>
        <data-iterator
            ref="iterator"
            :api="api"
            :filters.sync="filters"
            @update:filters="updateFilters"
        >
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="listFilters"
                    @input="updateFilters"
                ></table-filters>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    row-select
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <!-- name column -->
                    <template v-slot:[`item.name`]="{ item }">
                        <router-link :to="getEditLink(item)">
                            {{ item.name }}
                        </router-link>
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button :to="getEditLink(item)"></edit-button>
                        <edit-button
                            icon="people"
                            :tooltip="$t('labels.company_cloudcanteens')"
                            :to="{
                                name: routeType.COMPANY_CLOUDCANTEEN_LIST,
                                params: { companyId: item.id },
                            }"
                        ></edit-button>
                        <edit-button
                            icon="list_alt"
                            :tooltip="$t('labels.company_allowances')"
                            :to="{
                                name: routeType.COMPANY_ALLOWANCE_LIST,
                                params: { companyId: item.id },
                            }"
                        ></edit-button>
                        <table-button
                            :tooltip="$t('actions.copy')"
                            color="brandAlt"
                            icon="content_copy"
                            @execute="copyItem(item)"
                        ></table-button>
                        <delete-action
                            :entity-name="item.name"
                            :entity-id="item.id"
                            :api="api"
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
            </template>

            <!-- batch actions -->
            <template v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import RestApiType from '@/api/RestApiType'
import routeType from '@/router/routeType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import CompaniesApiClient from '@/api/RestApi/CompaniesApiClient'
import TableButton from '@/components/table/buttons/TableButton'
import Headers from '@/pages/companies/mixins/Headers'
import ListFilters from '@/pages/companies/mixins/ListFilters'

export default {
    components: { TableButton },
    mixins: [DataIteratorMixin, DataTableMixin, Headers, ListFilters],

    data() {
        return {
            api: RestApiType.COMPANIES,
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.companies')
        },
    },

    watch: {
        filters: {
            handler() {
                this.$emit('company-filter', this.filters)
            },
            deep: true,
        },
    },

    methods: {
        getEditLink(item, tab = 0) {
            return {
                name: routeType.COMPANY_EDIT,
                params: { companyId: item.id },
                query: { tab: tab },
            }
        },

        copyItem(item) {
            CompaniesApiClient.clone(item.id).then(() => {
                this.$set(this.filters, '_search', { value: item.name })
            })
        },
    },
}
</script>
